import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

/* Ruta Global */
import { GlobalUrl } from './globalUrl';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public url: string;

  constructor(
    public _http: HttpClient
  ){
    this.url = GlobalUrl.url;
  }

  test(){
    return 'Servicio de autenticacion activo ';
  }

  getAutenticationUser(){
    let user = localStorage.getItem('535510n');
    if(user){
      return true;
    }else{
      return false;
    }
  }
}
