import { Component, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

/* SERVICIOS */
import { ProfileService } from '../../services/profile.service';
import { CourseService } from '../../services/courses.service';
import { LogueoService } from '../../services/login.service';

/* MODELOS */
import { Profile } from '../../models/profile';
import { CoursesGrid } from '../../models/courses-grid';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;
@Component({
  selector: 'app-profile-courses',
  templateUrl: './profile-courses.component.html',
  styleUrls: ['./profile-courses.component.scss']
})
export class ProfileCoursesComponent implements OnInit {

  public profile: Profile; //detalle perfil
  public userCourse;
  public courses;
  public course = [];
  public files;
  public countCourse = {'count' : 0};
  public idUserCourses = []

  public loading : boolean;
  public noshow: boolean = false;
  public show:   boolean  = false;
  public show2:  boolean = false;
  public show3:  boolean = false;



  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _profileService: ProfileService,
    private _courseService: CourseService,
    private _login: LogueoService

  ){
    this.loading = true;
  }

  ngOnInit(): void {
    this.getUserCourse();
  }

  getUserCourse(){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);
    console.log(auth);
    this._courseService.getCoursesUser(auth).subscribe(
      response => {
        this.userCourse = response.data;
        this.getIdCourses(this.userCourse);
      },
      error => {
        this.loading  = false;
        this.noshow   = true;
        console.log('Error carga en usuarios en cursos');
        console.log(error);
      }
    )
  }

  getIdCourses(courses){
    let iduser = localStorage.getItem("535510n");
    for (let urls in courses ){
      console.log(courses);
      if(courses[urls].relationships['user_id'].data['id']== iduser){
        this.idUserCourses.push(courses[urls].relationships['course_id'].data['id']);
      }
    }
    if( this.idUserCourses.length == 0 ){
      this.loading  = false;
      this.noshow   = true;
    }
    this.countCourse['count'] += this.idUserCourses.length;
    this.CourseUser(this.idUserCourses);
  }

  CourseUser(idscourse){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);
    this._courseService.getCourses(auth).subscribe(
      response => {
        this.courses = response.data;
       // console.log(this.course);
        this.showCourseUser(idscourse, this.courses);
      },
      error => {
        this.loading  = false;
        this.noshow   = true;
        console.log('Error Carga de Cursos');
        console.log(error);
      }
    )
  }

  showCourseUser(idElement, element){
    for (let idCourseUser in idElement ){
      for (let idcourse in element ){
        if((element[idcourse].id)==(idElement[idCourseUser])){
          this.course.push(element[idcourse]);
        }
      }
    }
    //console.log(this.userCourses)
    this.decryptFiles(this.course);
  }

  /* DESENCRIPTAR ARCHIVOS */
  decryptFiles(courses){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);

    for (let urls in courses ){
      let urlCover = courses[urls].relationships['cover'].links['related'].href;
      let urlImage = courses[urls].relationships['image'].links['related'].href;

      /* Desencriptar la img de portada del curso */

      this._courseService.getFiles(urlCover,auth).subscribe(
        response => {
          this.files = response.data;
          courses[urls].relationships['cover'].links['related'].href = this.files.attributes['uri'].url;
        },
        error => {
         console.log('Error Carga de portada del curso');
         console.log(error);
        }
      )

      /* Desencriptar la img de perfil de curso */

      this._courseService.getFiles(urlImage,auth).subscribe(
        response => {
          this.files = response.data;
          courses[urls].relationships['image'].links['related'].href = this.files.attributes['uri'].url;
          this.loading  = false;
          this.show     = true;
        },
        error => {
         console.log('Error Carga de perfil del curso');
          console.log(error);
        }
      )
    }
    console.log(courses);
  }

  /* VISTAS DE LOS CURSOS */
  routerCourses(){
    this._router.navigate(['courses']);
  }

  switchGrid() {
    this.show  = true;this.show2 = false;this.show3 = false;
    $('#gridsmall').removeClass('active');
    $('#list').removeClass('active');
    $('#grid').addClass('active');
  }

  switchGridSmall() {
    this.show  = false;this.show2 = true;this.show3 = false;
    $('#grid').removeClass('active');
    $('#list').removeClass('active');
    $('#gridsmall').addClass('active');

  }

  switchList() {
    this.show  = false;this.show2 = false;this.show3 = true;
    $('#grid').removeClass('active');
    $('#gridsmall').removeClass('active');
    $('#list').addClass('active');
  }
}
