<app-navbar></app-navbar>
<div class="content-grid full">
  <article class="post-open">
    <figure class="post-open-cover liquid lessons">
      <img src="assets/img/cover/19.jpg" alt="cover-19">
    </figure>

    <div class="post-open-body">
      <div class="post-open-heading">
        <p class="post-open-timestamp"><span class="highlighted">Nombre del curso</span># Lección</p>

        <h2 class="post-open-title">¿Cómo y por qué me convertí en un transmisor de juegos a tiempo completo?</h2>
      </div>

      <div class="post-open-content">
        <div class="post-open-content-body">
          <p class="post-open-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

          <p class="post-open-paragraph">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>
          <figure class="post-open-image liquid">
            <img src="assets/img/cover/06.jpg" alt="cover-06">
          </figure>
          <p class="post-open-image-caption">Elemental Witches is the first game with which I started streaming</p>
          <p class="post-open-paragraph">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas estias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
        </div>
      </div>
    </div>
  </article>
</div>

<div class="content-grid medium">
  <div class="section-header medium">
    <div class="section-header-info">
      <p class="section-pretitle">Nombre del curso</p>
      <h2 class="section-title">Lecciones</h2>
    </div>
  </div>

  <div class="grid grid-4-4 centered">
    <div class="post-preview">
      <figure class="post-preview-image liquid">
        <img src="assets/img/cover/10.jpg" alt="cover-10">
      </figure>

      <div class="post-preview-info fixed-height">
        <div class="post-preview-info-top">
          <p class="post-preview-timestamp">3 weeks ago</p>
          <p class="post-preview-title">I spoke with the developers of RoBot SaMurai II at the 2019 GamingCon</p>
        </div>

        <div class="post-preview-info-bottom">
          <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

          <a class="post-preview-link" href="profile-post.html">Read more...</a>
        </div>
      </div>
    </div>

    <div class="post-preview">
      <figure class="post-preview-image liquid">
        <img src="assets/img/cover/20.jpg" alt="cover-20">
      </figure>

      <div class="post-preview-info fixed-height">
        <div class="post-preview-info-top">
          <p class="post-preview-timestamp">4 weeks ago</p>

          <p class="post-preview-title">I will be at this month's StreamCon with NekoBebop</p>
        </div>

        <div class="post-preview-info-bottom">
          <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

          <a class="post-preview-link" href="profile-post.html">Read more...</a>
        </div>
      </div>
    </div>
  </div>
</div>
