import { Component, OnInit } from '@angular/core';


/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loadScripts() {
    const dynamicScripts = [
      "../assets/js/utils/app.js",
        "../assets/js/utils/page-loader.js",
        "../assets/js/utils/svg-loader.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  constructor() {
    this.loadScripts();
   }

  ngOnInit(): void {}

}
