<div class="profile-header head-profile">
  <figure class="profile-header-cover liquid">
    <img src="assets/img/cover/01.jpg" alt="cover-01">
  </figure>

  <div class="profile-header-info">
    <div class="user-short-description big">
      <a class="user-short-description-avatar user-avatar big" (click)="ProfileRouting()">
        <div class="user-avatar-border">
          <div class="hexagon-148-164"></div>
        </div>

        <div class="user-avatar-content">
          <div class="hexagon-image-100-110" data-src="assets/img/avatar/01.jpg"></div>
        </div>

        <div class="user-avatar-progress">
          <div class="hexagon-progress-124-136"></div>
        </div>

        <div class="user-avatar-progress-border">
          <div class="hexagon-border-124-136"></div>
        </div>

        <div class="user-avatar-badge">
          <div class="user-avatar-badge-border">
            <div class="hexagon-40-44"></div>
          </div>

          <div class="user-avatar-badge-content">
            <div class="hexagon-dark-32-34"></div>
          </div>

          <p class="user-avatar-badge-text">23</p>
        </div>
      </a>

      <a class="user-short-description-avatar user-short-description-avatar-mobile user-avatar medium">
        <div class="user-avatar-border">
          <div class="hexagon-120-132"></div>
        </div>

        <div class="user-avatar-content">
          <div class="hexagon-image-82-90" data-src="assets/img/avatar/01.jpg"></div>
        </div>

        <div class="user-avatar-progress">
          <div class="hexagon-progress-100-110"></div>
        </div>

        <div class="user-avatar-progress-border">
          <div class="hexagon-border-100-110"></div>
         </div>

        <div class="user-avatar-badge">
          <div class="user-avatar-badge-border">
            <div class="hexagon-32-36"></div>
          </div>

          <div class="user-avatar-badge-content">
            <div class="hexagon-dark-26-28"></div>
          </div>

          <p class="user-avatar-badge-text">23</p>
        </div>
      </a>
      <p class="user-short-description-title"><a (click)="ProfileRouting()">Mariana Domingues</a></p>
      <p class="user-short-description-text">EL PESIMISMO LLEVA A LA DEBILIDAD. EL OPTIMISMO, AL PODER</p>
    </div>

  </div>
</div>
