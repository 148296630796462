import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {
  loadScripts() {
    const dynamicScripts = [
        "../assets/js/header/header.js",

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.loadScripts();
  }

  ProfileRouting(){
    this._router.navigate(['profile']);
  }
}
