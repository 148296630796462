<head>
  <title>+Turimo Educativo | Inicio</title>
</head>
<div class="landing">
    <div class="landing-decoration"></div>

    <div class="landing-info">
      <div class="logo">
        <img src="../../../assets/img/turismo.png" alt="">
      </div>

      <h2 class="landing-info-pretitle">Bienvenido a</h2>
      <img src="../../../assets/img/logo_blanco.png" alt="LOGO" class="img-info">

      <p class="landing-info-text">
        La nueva plataforma educativa de próxima generación y ¡Comunidad! ¡Descarga tus cursos preferidos y disfruta aprendiendo!
      </p>

      <div class="tab-switch">
        <p class="tab-switch-button login-register-form-trigger login active">Iniciar Sesión</p>
        <p class="tab-switch-button login-register-form-trigger register">Registrarse</p>
      </div>
    </div>

    <!-- LANDING FORM -->
    <div class="landing-form">
      <!-- FORM BOX -->
      <div class="form-box login-register-form-element">
        <img class="form-box-decoration overflowing" src="assets/img/landing/rocket.png" alt="Cohete">
        <h2 class="form-box-title">Cuenta de Ingreso</h2>

          <div id="message" class="pt-2" align="center">
            {{mensaje}}
          </div>

        <!-- FORMULARIO DE INICIO DE SESION -->
       <form class="form"  #login="ngForm" (ngSubmit)="onSubmit(login)">
          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="one">
                <label for="login-username">Usuario</label>
                <input type="email" id="login-username" name="login_username" #email="ngModel" [(ngModel)]="Account.name" required>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="two">
                <label for="login-password">Contrase&ntilde;a</label>
                <input type="password" id="login-password" name="login_password" #passworduser="ngModel" [(ngModel)]="Account.pass" required>
                <small *ngIf="!passworduser.valid && passworduser.touched" class="invalid-feedback d-block">
                  Debes ingresar la Contrase&ntilde;a
              </small>
              </div>
            </div>
          </div>

        <!--   <div class="form-row space-between">
                  <div class="form-item">
                    <div class="checkbox-wrap">
                      <input type="checkbox" id="login-remember" name="login_remember" checked>
                      <div class="checkbox-box">
                        <svg class="icon-cross">
                          <use xlink:href="#svg-cross"></use>
                        </svg>
                      </div>
                      <label for="login-remember">Recordarme</label>
                    </div>
                </div> -->
                <div class="form-row">
                  <div class="form-item" align="center">
                    <input type="submit" style="margin-top: 3em;" class="button medium secondary"  value="¡Ingrese a su Cuenta!">
                  </div>
                </div>
          </form>

        <!-- /FORM -->

      </div>
      <!-- /FORMULARIO DE INICIO DE SESION -->

      <!----------- FORMULARIO DE REGISTRO----------->

      <div class="form-box login-register-form-element">
        <img class="form-box-decoration" src="assets/img/landing/rocket.png" alt="Cohete">

        <h2 class="form-box-title">¡Crea tu Cuenta!</h2>
        <div id="message2" class="pt-2" align="center">
          {{mensaje}}
        </div>


        <!-- FORM -->
        <form class="form" #createAccount="ngForm" (ngSubmit)="onSubmitRegister(createAccount)">
          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="three">
                <label for="register-email">Correo Electrónico</label>
                <input type="email" id="register-email" name="register_email" #correo="ngModel" [(ngModel)]="registerAccount.mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>

                <div *ngIf="user.invalid && (user.dirty || user.touched)" class="invalid-feedback d-block">
                  <small *ngIf="email.errors.required">
                    El Correo electronico es un campo obligatorio
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="four">
                <label for="register-username">Usuario</label>
                <input type="text" id="register-username" name="register_username" #user="ngModel" [(ngModel)]="registerAccount.name" required appForbiddenName="user">
                <div *ngIf="user.invalid && (user.dirty || user.touched)" class="invalid-feedback d-block">
                  <small *ngIf="user.errors.required">
                    El Usuario es un campo obligatorio
                  </small>
                  <small *ngIf="user.errors.minlength">
                    La contraseña no puede tener menos de 4 caracteres
                  </small>
                  <small *ngIf="user.errors.forbiddenName">
                    El nombre no puede ser Usuario
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="five">
                <label for="register-password">Contrase&ntilde;a</label>
                <input type="password" id="register-password" name="register_password" #password="ngModel" [(ngModel)]="registerAccount.pass" required minlength="6" maxlength="15">
                <div *ngIf="user.invalid && (user.dirty || user.touched)" class="invalid-feedback d-block">
                  <small *ngIf="user.errors.required">
                    La contraseña es un campo obligatorio
                  </small>
                  <small *ngIf="user.errors.minlength">
                    La contraseña no puede tener menos de 4 caracteres
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item">
              <div class="form-input" id="six">
                <label for="register-password-repeat">Confirmar Contrase&ntilde;a</label>
                <input type="password" id="register-password-repeat" name="register_password_repeat" #passwordconfirm="ngModel" [(ngModel)]="registerAccount.pass2"  required>
                <small class="invalid-feedback" id="password-input">
                  Las contrase&ntilde;as deben coincidir
              </small>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item"  align="center">
              <input type="submit" class="button medium primary"  value="Registrarme Ahora!" id="button-register">
            </div>
          </div>
        </form>
        <!-- /FORM -->
      </div>
    </div>
    <!-- /LANDING FORM -->
</div>


