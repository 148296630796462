import { NgModule } from '@angular/core';
import{ Routes, RouterModule } from '@angular/router';

/* PAGINAS */
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileCoursesComponent } from './pages/profile-courses/profile-courses.component';
import { DetailCourseComponent } from './pages/detail-course/detail-course.component';
import { LessonsComponent } from './pages/lessons/lessons.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { LoaderComponent } from './components/loader/loader.component';

import { AuthGuard } from './helpers/auth.guard';
import { LogGuard } from './helpers/log.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [LogGuard]
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'profile-courses',
    component: ProfileCoursesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'courses',
    component: CoursesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'detail-course/:course',
    component: DetailCourseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lessons',
    component: LessonsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**', pathMatch: 'full',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true })
  ],
  exports:[
    RouterModule
  ]
})
export class AppRoutingModule { }
