<head>
  <title>+Turismo | Cursos</title>
</head>
<!-- MENU -->
<app-navbar></app-navbar>
<!-- MENU -->

<div class="profile-courses">
  <div class="content-grid">

    <!-- HEADER PROFILE -->
   <app-header-profile></app-header-profile>
    <!-- HEADER PROFILE -->

    <section class="section">
      <div class="section-header">
        <div class="section-header-info">
          <p class="section-pretitle">Navegación</p>

         <!--  <h2 class="section-title">Cursos <span class="highlighted">{{this.countCourse['count']}}</span></h2> -->
        </div>
      </div>

      <div class="section-filters-bar v1">
        <div class="section-filters-bar-actions ">
          <div class="view-actions">
            <a class="view-action text-tooltip-tft-medium active" data-title="Cuadrícula Grande" (click)="switchGrid()" id="grid" >
              <svg class="view-action-icon icon-big-grid-view">
                <use xlink:href="#svg-big-grid-view"></use>
              </svg>
            </a>

            <a class="view-action text-tooltip-tft-medium"
             data-title="Cuadrícula Pequeña" (click)="switchGridSmall()" id="gridsmall">
              <svg class="view-action-icon icon-small-grid-view">
                <use xlink:href="#svg-small-grid-view"></use>
              </svg>
            </a>

            <a class="view-action text-tooltip-tft-medium" data-title="Lista" (click)="switchList()" id="list">
              <svg class="view-action-icon icon-list-grid-view">
                <use xlink:href="#svg-list-grid-view"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="show">
        <app-courses-grid [data]='courses'></app-courses-grid>
      </div>

      <div *ngIf="show2">
        <app-courses-small-grid  [data]='courses'></app-courses-small-grid>
      </div>

      <div *ngIf="show3">
        <app-courses-list  [data]='courses' ></app-courses-list>
      </div>
    </section>
  </div>
</div>
