import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as Cookies from 'js-cookie';

/* SERVICIOS */
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../models/profile';
import { LogueoService } from '../../services/login.service';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

loadScripts() {
    const dynamicScripts = [
        "../assets/js/utils/app.js",
        "../assets/js/vendor/simplebar.min.js",
        "../assets/js/utils/liquidify.js",
        "../assets/js/vendor/xm_plugins.min.js",
        "../assets/js/vendor/tiny-slider.min.js",
        "../assets/js/global/global.hexagons.js",
        "../assets/js/global/global.tooltips.js",
        "../assets/js/header/header.js",
        "../assets/js/sidebar/sidebar.js",
        "../assets/js/content/content.js",
        "../assets/js/form/form.utils.js",
        "../assets/js/utils/svg-loader.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  public profile: Profile; //detalle perfil

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _profileService: ProfileService,
    private _logout: LogueoService
  ){
   this.loadScripts();
  }

  ngOnInit(): void {
  //this.loadScripts();
   /*  this._route.params.subscribe(params => {
      this._profileService.getProfile().subscribe(
        response => {
          if(response.status == 'success'){
            //this.profile = response.user;
            console.log(response);
            //console.log(this.profile);
          }
        },
        Error=> {
          console.log('error al obtener el perfil');
          this._router.navigate(['']);
        }
      );
    });
 */
  }

  logout(){
    localStorage.removeItem('535510n');
    localStorage.removeItem('Sha');
    localStorage.removeItem('N6m3');
    this._router.navigate(['']);
  }

  routerProfile(){
    this._router.navigate(['profile']);
  }
  routerProfileCourses(){
    this._router.navigate(['profile-courses']);
  }
  routerCourses(){
    this._router.navigate(['courses']);
  }

}
