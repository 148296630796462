import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CourseService } from '../../services/courses.service';
import { LogueoService } from '../../services/login.service';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss']
})
export class DetailCourseComponent implements OnInit {

  loadScripts() {
    const dynamicScripts = [
      "../assets/js/utils/app.js",
      "../assets/js/vendor/xm_plugins.min.js",
      "../assets/js/form/form.utils.js",
      "../assets/js/utils/liquidify.js",
      "../assets/js/global/global.hexagons.js"

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


  public courses;
  public course;
  public files;
  public lessons;
  public idCourse; /* ID de la relacion curso */
  public countLesson;

  show: boolean   = true;
  show2: boolean  = false;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _courseService: CourseService,
    private _login: LogueoService
  ) { }

  ngOnInit(): void {
    let _this = this;
    this.getCourseInfo();
    this.LoadLessons();
    setTimeout(function(){
      _this.loadScripts();
      $('.forum-post-paragraph').addClass('post-paragraph');
      $('.post-preview .post-preview-info.fixed-height ').css('justify-content', 'flex-start')
      $('.post-preview-info-bottom').css('margin-top','20px');
    }, 3000);
  }

  /* OBTENGO TODOS LOS CURSOS */
/*   getCourse(){
    this._route.params.subscribe(params => {
      let id = params.course;
      let key = localStorage.getItem("Sha");
      let auth = this._login.codeDecript(key);
      this._courseService.getCourses(auth).subscribe(
        response => {
          console.log(response.data);
          this.ChooseCourse(response.data,id);
          console.log('Carga de cursos satisfactoriamente');
        },
        error => {
          console.log('Error Carga de Cursos');
          console.log(error);
        }
      )
    });
  } */

  getCourseInfo(){
    let idUser = localStorage.getItem("535510n");

    let key = localStorage.getItem("Sha");

    let auth = this._login.codeDecript(key);

    this._route.params.subscribe(params => {
      let id = params.course;
      //console.log(id);

      this._courseService.getCourseInfo(id, auth).subscribe(
        Response => {
          this.course = Response.data;
          console.log(this.course);
          this.show = false;
          this.show2 = true;
        },
        error => {
          console.log(error);
        }
      )
    });
  }

  LoadLessons(){
    let key = localStorage.getItem("Sha");

    let auth = this._login.codeDecript(key);

    console.log('h')

    this._courseService.getLessonInfo(auth).subscribe(
      Response => {
        this.lessons = Response.data;
        this.countLesson = this.lessons.length;
        console.log(this.lessons);
      },
      error => {
        console.log(error);
      }
    )
  }

  /* ELIJO EL CURSO QUE SE ME PASA POR RUTA */
  ChooseCourse(courses, id){
    let auth = localStorage.getItem("Sha");
    for (let urls in courses ){
      if((courses[urls].id) === (id)){
        this.course = courses[urls];
       /*  console.log(this.course);
        let urlCover = courses[urls].relationships['cover'].links['related'].href;
        let urlImage = courses[urls].relationships['image'].links['related'].href; */

        /* Desencriptar la img de portada del curso */

       /*  this._courseService.getFiles(urlCover,auth).subscribe(
          response => {
            this.files = response.data;
            courses[urls].relationships['cover'].links['related'].href = this.files.attributes['uri'].url;
          },
          error => {
            console.log('Error Carga de portada');
            console.log(error);
          }
        )
 */
        /* Desencriptar la img de perfil de curso */

      /*   this._courseService.getFiles(urlImage,auth).subscribe(
              response => {
                this.files = response.data;
                courses[urls].relationships['cover'].links['related'].href = this.files.attributes['uri'].url;
              },
              error => {
                console.log('Error Carga de imagen de perfil');
                console.log(error);
              }
            )
        } */
      }
    }
  }

  joinCourse(id, name){
    let uid = localStorage.getItem('535510n');
    let uname = localStorage.getItem('N6m3');
    let joinCourse = {
      "data": {
        "type": "usercourses--usercourses",
        "attributes": {
          "name": uname + " + " + name
        },
        "relationships": {
          "user_id": {
            "data": {
              "type": "user--user",
              "id": uid
            }
          },
          "course_id": {
            "data": {
              "type": "courses--courses",
              "id": id
            }
          }
        }
      }
    }

    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);

    this._courseService.enrollCourse(joinCourse,auth).subscribe(
      response => {
        alert('Curso Inscrito Correctamente');
        this.show = false;
        this.show2 = true;
      },
      error => {
        console.log('Error en la inscripcion de cursos');
        console.log(error);
      }
    )

  }

  deleteCourse(){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);
    this._courseService.deleteCourse(this.idCourse,auth).subscribe(
      response => {
        alert('Curso Cancelado Correctamente');
        this.show = true;
        this.show2 = false;
      },
      error => {
        console.log('Error en la inscripcion de cursos');
        console.log(error);
      }
    )
    console.log('cancelar curso');
  }

  routerLession(){
    this._router.navigate(['/lessons']);
  }

}
