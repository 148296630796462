import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable} from 'rxjs';

/* Ruta Global */
import { GlobalUrl } from './globalUrl';
import * as crypto from 'crypto-js';
import {environment} from '../../environments/environment';


const AUTH_API = environment.api.url;

@Injectable({
  providedIn: 'root'
})

export class LogueoService {
  public url: string;

  constructor(
    public _http: HttpClient
  ){
    this.url = GlobalUrl.url;
  }

  test(){
    return 'Servicio de login activo';
  }

  logueo(account):Observable<any>{
    let json = JSON.stringify(account);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');


   //return this._http.post('user/login?_format=json', json, {headers, observe:'response'});
   return this._http.post(this.url + 'user/login?_format=json', json, {headers : headers, observe:'response'});
  }

  registerUser(registerAccount):Observable<any>{
    let json = JSON.stringify(registerAccount);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    //return this._http.post('user/newuserregister?_format=json', json, {headers, observe:'response'});
    return this._http.post(this.url + 'user/newuserregister?_format=json', json, {headers});
  }

  getUid(id,account):Observable<any>{
    let headers = new HttpHeaders();
    //console.log(id);
    headers = headers.append('Authorization', `Basic ${account}`);
    //return this._http.get(this.url + 'user/' + id + '?_format=json', {headers: headers});
    return this._http.get('https://testapi.io/api/baquintero/userid', {headers});
  }

  codeEncript(code){
    //console.log(code);
    let a = window.btoa(code);
    let i,
        l,
        o = '',
        n;
    a += ''

    for (i = 0, l = a.length; i < l; i++) {
      n = a.charCodeAt(i)
        .toString(16)
      o += n.length < 2 ? '0' + n : n
    }

    let g = window.btoa(o);
    //console.log(g);
    return g;
  }

  codeDecript(code){
     let h = window.atob(code);
     var hex = h,
     bytes = [],
     str;

    for(let f=0; f< hex.length-1; f+=2){
        bytes.push(parseInt(hex.substr(f, 2), 16));
    }

    str = String.fromCharCode.apply(String, bytes);
    //console.log(str);
    return str;
  }
}

