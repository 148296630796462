import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

/* SERVICIOS */
import { LogueoService } from '../../services/login.service';

/* MODELOS */
import { Register } from '../../models/register';
import { Login } from '../../models/login';
import { FormBuilder, FormControl, FormGroup, Validators  } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

 loadScripts() {
    const dynamicScripts = [
        "../assets/js/utils/app.js",
        "../assets/js/vendor/xm_plugins.min.js",
        "../assets/js/form/form.utils.js",
        "../assets/js/landing/landing.tabs.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  public mensaje;
  public registerAccount: Register;
  public Account: Login;
  public sessionToken;
  public user;


  constructor(
    private _login: LogueoService,
    private _router: Router,
    private _cookie: CookieService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
  ) {
    this.loadScripts();
    this.registerAccount = new Register('', '', '', '');
    this.Account = new Login('', '');
  }

  ngOnInit(){

  }

  /* INICIO DE SESION  */
  onSubmit(form){
    console.log('hey')
      let key = this._login.codeEncript(this.Account['name'] +   ':' + this.Account['pass']);
    localStorage.setItem('Sha', key);

    this._login.logueo(this.Account).subscribe(
      (Response: any) =>{
        this.user = Response.body;
        if(Response.status == 200){
          this.idUser(this.user.current_user.uid);
        }
        form.reset();
      },
      Error=> {
        console.log(<any>Error);
        this.snackBar.open(Error.error.Message, "Cerrar");
        if(Error.status == 403){
          this.mensaje = 'El usuario ya se encuentra en una sesion';
          $('#message').addClass('message-incorrect');
        }
        if(Error.status == 400){
          this.mensaje = 'El usuario ingresado no existe';
          $('#message').addClass('message-incorrect');
        }
      })
  }

  /* CREACION DE CUENTA DE USUARIO*/
  onSubmitRegister(form){
    let signup = {
          "mail" : [{"value": this.registerAccount['mail']}],
          "name" : [{"value": this.registerAccount['name']}],
          "pass" : [{"value": this.registerAccount['pass']}]
    }
    console.log('hey')
    let key = this._login.codeEncript(this.registerAccount['name'] +   ':' + this.registerAccount['pass']);
    localStorage.setItem('Sha', key);
    console.log(signup);
      if( (this.registerAccount['pass']) === (this.registerAccount['pass2'])){
        $('#password-input').removeClass('password-incorrect');
        $('#password-input').addClass('password-correct');

        this._login.registerUser(signup).subscribe(
          Response=> {
            console.log(Response);
            localStorage.setItem('535510n', Response.uuid[0].value.toString());
            localStorage.setItem('N6m3', Response.name[0].value.toString());
            this._router.navigate(['profile-courses']);
          },
          Error=> {
            console.log(<any>Error);
            if(Error.status == 403){
              this.mensaje = 'Acceso Denegado';
              $('#message2').addClass('message-incorrect');
            }
            if(Error.status == 409){
              this.mensaje = 'El usuario que desea crear ya existe';
              $('#message2').addClass('message-incorrect');
            }
            if(Error.status == 422){
              this.mensaje = 'El correo electronico o el nombre de usuario que desea crear ya existe';
              $('#message2').addClass('message-incorrect');
            }
          }
        )
      }else{
        $('#password-input').addClass("password-incorrect");
      }
  }

  idUser(id){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);
    this._login.getUid(id,auth).subscribe(
      (Response: any) =>{
        localStorage.setItem('535510n', Response.uuid[0].value.toString());
        localStorage.setItem('N6m3', Response.name[0].value.toString());
        this._router.navigate(['/profile-courses']);
      },
      Error=> {
        console.log(<any>Error);
      })
  }

}
