import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  loadScripts() {
    const dynamicScripts = [
      "../assets/js/utils/liquidify.js",
      "../assets/js/global/global.hexagons.js"

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  @Input('data') cursos: any;
  constructor(
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.loadScripts();
  }
  routerDetailCourse(){
    this._router.navigate(['detail-course']);
  }
}
