/* MODULOS */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { ComponentsModule } from '../components/components.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule} from '@angular/material/tooltip';

/* COMPONENTES */
import { HomeComponent } from './home/home.component';
import { ProfileCoursesComponent } from './profile-courses/profile-courses.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { DetailCourseComponent } from './detail-course/detail-course.component';
import { LessonsComponent } from './lessons/lessons.component';
import { CoursesComponent } from './courses/courses.component';


@NgModule({
  declarations: [
    HomeComponent,
    ProfileCoursesComponent,
    NotFoundComponent,
    ProfileComponent,
    DetailCourseComponent,
    LessonsComponent,
    CoursesComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCheckboxModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ]
})
export class PagesModule { }
