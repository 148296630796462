<div class="error-section">

  <p class="error-section-title">404</p>

  <div class="error-section-info">
    <p class="error-section-subtitle">Ups!!...</p>

    <p class="error-section-text">
      ¡Parece que hiciste un giro equivocado y te topaste con un agujero negro en la red que absorbió la página que estabas buscando! ¡Pero que no cunda el pánico porque puedes volver!
    </p>

    <p class="error-section-text">
      Si el problema persiste, envíenos un correo electrónico a nuestro equipo de soporte a<a href="#">support@cienciaenfamilia.com</a>
    </p>

    <a class="button medium primary" href="profile-timeline.html">Go Back</a>
  </div>
</div>
