  <!-- BARRA IZQUIERDA PEQUEÑA -->
  <nav id="navigation-widget-small" class="navigation-widget navigation-widget-desktop closed sidebar left delayed">
    <a class="user-avatar small no-outline online">
      <div class="user-avatar-content">
        <div class="hexagon-image-30-32" data-src="assets/img/avatar/01.jpg"></div>
      </div>

      <div class="user-avatar-progress">
        <div class="hexagon-progress-40-44"></div>
      </div>

      <div class="user-avatar-progress-border">
        <div class="hexagon-border-40-44"></div>
      </div>

      <div class="user-avatar-badge">
        <div class="user-avatar-badge-border">
          <div class="hexagon-22-24"></div>
        </div>

        <div class="user-avatar-badge-content">
          <div class="hexagon-dark-16-18"></div>
        </div>

        <p class="user-avatar-badge-text">23</p>
      </div>
    </a>

    <!-- MENU -->
    <ul class="menu small">
      <li class="menu-item">
        <a class="menu-item-link text-tooltip-tfr" data-title="Perfil" (click)="routerProfile()">
          <svg class="menu-item-link-icon icon-members">
            <use xlink:href="#svg-members"></use>
          </svg>
        </a>
      </li>

      <li class="menu-item">
        <a class="menu-item-link text-tooltip-tfr" data-title="Mis Cursos" (click)="routerProfileCourses()">
          <svg class="menu-item-link-icon icon-streams">
            <use xlink:href="#svg-streams"></use>
          </svg>
        </a>
      </li>
    </ul>
    <!-- /MENU -->
  </nav>

  <!-- BARRA IZQUIERDA GRANDE -->
  <nav id="navigation-widget" class="navigation-widget navigation-widget-desktop sidebar left hidden" data-simplebar>

    <figure class="navigation-widget-cover liquid">
      <img src="assets/img/cover/01.jpg" alt="cover-01">
    </figure>

    <div class="user-short-description">
      <a class="user-short-description-avatar user-avatar medium">
        <div class="user-avatar-border">
          <div class="hexagon-120-132"></div>
        </div>

        <div class="user-avatar-content">
          <div class="hexagon-image-82-90" data-src="assets/img/avatar/01.jpg"></div>
        </div>

        <div class="user-avatar-progress">
          <div class="hexagon-progress-100-110"></div>
        </div>

        <div class="user-avatar-progress-border">
          <div class="hexagon-border-100-110"></div>
        </div>

        <div class="user-avatar-badge">
          <div class="user-avatar-badge-border">
            <div class="hexagon-32-36"></div>
          </div>

          <div class="user-avatar-badge-content">
            <div class="hexagon-dark-26-28"></div>
          </div>

          <p class="user-avatar-badge-text">23</p>
        </div>
      </a>

      <p class="user-short-description-title"><a>Mariana Dominguez</a></p>
      <p class="user-short-description-text"><a href="#">El pesimismo lleva a la debilidad. El optimismo, al poder</a></p>
    </div>

    <div class="user-stats">
      <div class="user-stat">
        <p class="user-stat-title">2</p>

        <p class="user-stat-text">Cursos</p>
      </div>

      <div class="user-stat">
        <p class="user-stat-title">2</p>

        <p class="user-stat-text">Cursos Iniciados</p>
      </div>

      <div class="user-stat">
        <p class="user-stat-title">1</p>
        <p class="user-stat-text">Cursos Completados</p>
      </div>
    </div>

    <div align="center" class="mt-4" >
      <p  class="navigation-widget-info-button button medium secondary pl-5 pr-5" (click)="logout()">Cerrar Sesion</p>
    </div>

    <ul class="menu">
      <li class="menu-item">
        <a class="menu-item-link" (click)="routerProfile()">
          <svg class="menu-item-link-icon icon-members">
            <use xlink:href="#svg-members"></use>
          </svg>
          Perfil
        </a>
      </li>

      <li class="menu-item">
        <a class="menu-item-link" (click)="routerProfileCourses()">
          <svg class="menu-item-link-icon icon-streams">
            <use xlink:href="#svg-streams"></use>
          </svg>
          Mis Cursos
        </a>
      </li>

    </ul>
    <!-- /MENU -->
  </nav>

  <!-- BARRA IZQUIERDA GRANDE HEADER MOVIL -->
  <nav id="navigation-widget-mobile" class="navigation-widget navigation-widget-mobile sidebar left hidden" data-simplebar>
    <div class="navigation-widget-close-button">
      <svg class="navigation-widget-close-button-icon icon-back-arrow">
        <use xlink:href="#svg-back-arrow"></use>
      </svg>
    </div>

    <div class="navigation-widget-info-wrap">
      <div class="navigation-widget-info">
        <a class="user-avatar small no-outline">
          <div class="user-avatar-content">
            <div class="hexagon-image-30-32" data-src="assets/img/avatar/01.jpg"></div>
          </div>

          <div class="user-avatar-progress">
            <div class="hexagon-progress-40-44"></div>
          </div>

          <div class="user-avatar-progress-border">
            <div class="hexagon-border-40-44"></div>
          </div>

          <div class="user-avatar-badge">
            <div class="user-avatar-badge-border">
              <div class="hexagon-22-24"></div>
            </div>

            <div class="user-avatar-badge-content">
              <div class="hexagon-dark-16-18"></div>
            </div>

            <p class="user-avatar-badge-text">23</p>
          </div>
        </a>

        <p class="navigation-widget-info-title"><a>Mariana Dominguez</a></p>

        <p class="navigation-widget-info-text">Bienvenido!</p>
      </div>

      <p class="navigation-widget-info-button button small secondary" (click)="logout()">Cerrar Sesion</p>
    </div>

    <p class="navigation-widget-section-title">Sección</p>

    <ul class="menu">
      <li class="menu-item">
        <a class="menu-item-link" (click)="routerProfile()">
          <svg class="menu-item-link-icon icon-members">
            <use xlink:href="#svg-members"></use>
          </svg>
          Perfil
        </a>
      </li>

      <li class="menu-item">
        <a class="menu-item-link" (click)="routerProfileCourses()">
          <svg class="menu-item-link-icon icon-streams">
            <use xlink:href="#svg-streams"></use>
          </svg>
          Mis Cursos
        </a>
      </li>
    </ul>

  </nav>

  <header class="header">
    <div class="header-actions">
      <div class="header-brand">
        <div class="logo">
          <img src="../../../assets/img/turismo.png" alt="logo">
        </div>
        <h1 class="header-brand-text">+TURISMO EDUCACIÓN</h1>
      </div>
    </div>
    <div class="header-actions">
      <div class="sidemenu-trigger navigation-widget-trigger">
        <svg class="icon-grid">
          <use xlink:href="#svg-grid"></use>
        </svg>
      </div>
      <div class="mobilemenu-trigger navigation-widget-mobile-trigger">
        <div class="burger-icon inverted">
          <div class="burger-icon-bar"></div>
          <div class="burger-icon-bar"></div>
          <div class="burger-icon-bar"></div>
        </div>
      </div>

      <nav class="navigation">
        <ul class="menu-main">
          <li class="menu-main-item">
            <a class="menu-main-item-link" href="#">Inicio</a>
          </li>
          <li class="menu-main-item">
            <a class="menu-main-item-link" (click)="routerCourses()">Cursos</a>
          </li>

          <li class="menu-main-item">
            <a class="menu-main-item-link" (click)="logout()">Cerrar Sesion</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="header-actions"></div>
    <div class="header-actions"></div>

  </header>
