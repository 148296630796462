import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable} from 'rxjs';

/* Ruta Global */
import { GlobalUrl } from './globalUrl';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  public url: string;
  public url2: string;

  constructor(
    public _http: HttpClient
  ){
    this.url  = GlobalUrl.url;
  }

  enrollCourse(course, account){
    let json = JSON.stringify(course);
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/vnd.api+json')
    headers = headers.append('Accept', 'application/vnd.api+json');
    headers = headers.append('Authorization', `Basic ${account}`);

    //return this._http.post('jsonapi/usercourses/usercourses', json, {headers: headers});
    return this._http.post(this.url+'/jsonapi/enrollment/enrollment', json, {headers: headers});
  }

  deleteCourse(id, account){
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/vnd.api+json');
    headers = headers.append('Authorization', `Basic ${account}`);

    //return this._http.post('jsonapi/usercourses/usercourses', json, {headers: headers});
    return this._http.delete(this.url+'jsonapi/usercourses/usercourses/'+ id, {headers: headers});
  }

  getCourses(account):Observable<any>{
    let headers = new HttpHeaders();
   /*  headers = headers.append('Content-Type', 'application/vnd.api+json')
    headers = headers.append('Accept', 'application/vnd.api+json'); */
    headers = headers.append('Authorization', `Basic ${account}`);


    //return this._http.get('jsonapi/courses/courses', {headers: headers});
    return this._http.get(this.url + 'jsonapi/courses/courses', {headers: headers});
  }

  getCoursesUser(account):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.api+json');
    headers = headers.append('Authorization', `Basic ${account}`);

    //return this._http.get('jsonapi/usercourses/usercourses', {headers: headers});
    return this._http.get(this.url + 'jsonapi/usercourses/usercourses', {headers:headers});
  }

  getFiles(url, account):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Basic ${account}`);

    return this._http.get(url, {headers: headers});
  }

  getCourseInfo(idCourse , account):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Basic ${account}`);

    return this._http.get(this.url + '/jsonapi/courses/courses/' + idCourse, {headers: headers});
  }

  getLessonInfo(account):Observable<any>{
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Basic ${account}`);

    return this._http.get(this.url + '/jsonapi/lession/lession', {headers: headers});
  }
}


