import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-config',
  templateUrl: './profile-config.component.html',
  styleUrls: ['./profile-config.component.scss']
})
export class ProfileConfigComponent implements OnInit {
/*
  loadScripts() {
    const dynamicScripts = [
        "../assets/js/utils/app.js",
        "../assets/js/vendor/simplebar.min.js",
        "../assets/js/utils/liquidify.js",
        "../assets/js/vendor/xm_plugins.min.js",
        "../assets/js/global/global.hexagons.js",
        "../assets/js/global/global.tooltips.js",
        "../assets/js/global/global.accordions.js",
        "../assets/js/header/header.js",
        "../assets/js/sidebar/sidebar.js",
        "../assets/js/content/content.js",
        "../assets/js/form/form.utils.js",
        "../assets/js/utils/svg-loader.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  } */
  constructor() { }

  ngOnInit(): void {
  //  this.loadScripts();
  }
}
