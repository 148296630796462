<div class="page-loader">
    <div class="logo">
      <img src="../../../assets/img/turismo.png" alt="">
    </div>

  <div class="page-loader-info">
    <p class="page-loader-info-title">+Turismo</p>

    <p class="page-loader-info-text">Cargando...</p>
  </div>
  <div class="page-loader-indicator loader-bars">
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
    <div class="loader-bar"></div>
  </div>
  </div>
