import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProfileConfigComponent } from './profile-config/profile-config.component';
import { ProfileConfigCoursesComponent } from './profile-config-courses/profile-config-courses.component';
import { CoursesGridComponent } from './courses-grid/courses-grid.component';
import { CoursesListComponent } from './courses-list/courses-list.component';
import { CoursesSmallGridComponent } from './courses-small-grid/courses-small-grid.component';
import { HeaderProfileComponent } from './header-profile/header-profile.component';


@NgModule({
  declarations: [
    NavbarComponent,
    ProfileConfigComponent,
    ProfileConfigCoursesComponent,
    CoursesGridComponent,
    CoursesListComponent,
    CoursesSmallGridComponent,
    HeaderProfileComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NavbarComponent,
    ProfileConfigComponent,
    ProfileConfigCoursesComponent,
    CoursesGridComponent,
    CoursesListComponent,
    CoursesSmallGridComponent,
    HeaderProfileComponent,
    LoaderComponent
  ]
})

export class ComponentsModule { }
