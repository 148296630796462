<head>
  <title>+Turismo | Detalle del Curso</title>
</head>
<app-navbar></app-navbar>

<div class="content-grid">
<!-- HEADER PROFILE -->
<app-header-profile></app-header-profile>
<!-- HEADER PROFILE -->

  <section class="section">
    <div class="section-header">
      <div class="section-header-info">
        <p class="section-pretitle">{{course.attributes['title']}}</p>
        <h2 class="section-title">Lecciones <span class="highlighted"> {{countLesson}}</span></h2>
      </div>
    </div>

    <div class="section-filters-bar v2">
      <form class="form">
        <div class="form-item split medium">

          <div class="form-select">
            <label for="post-filter-show">Mostrar</label>
            <select id="post-filter-show" name="post_filter_show">
              <option value="0">12 Lecciones por página</option>
              <option value="1">24 Lecciones por página</option>
            </select>
            <svg class="form-select-icon icon-small-arrow">
              <use xlink:href="#svg-small-arrow"></use>
            </svg>
          </div>
          <div *ngIf="show">
            <button class="button secondary" (click)="joinCourse(course.id,course.attributes['title'])">Unirme al Curso</button>
          </div>
          <div *ngIf="show2">
            <button class="button primary" (click)="deleteCourse()">Cancelar Curso</button>
          </div>
        </div>
      </form>
    </div>

    <!-- INFO DEL CURSO -->
    <div  class="grid grid-11-1 centered">
      <div class="forum-post-list">
        <div class="forum-post">
          <div class="forum-post-content">
            <div class="forum-post-user">
              <a class="user-avatar no-outline">
                <div class="user-avatar-content">
                  <div class="hexagon-image-68-74" data-src="assets/img/avatar/07.jpg"></div>
                </div>

                <div class="user-avatar-progress">
                  <div class="hexagon-progress-84-92"></div>
                </div>

                <div class="user-avatar-progress-border">
                  <div class="hexagon-border-84-92"></div>
                </div>

                <div class="user-avatar-badge">
                  <div class="user-avatar-badge-border">
                    <div class="hexagon-28-32"></div>
                  </div>

                  <div class="user-avatar-badge-content">
                    <div class="hexagon-dark-22-24"></div>
                  </div>

                  <p class="user-avatar-badge-text">26</p>
                </div>
              </a>
              <a class="user-avatar small no-outline" >
                <div class="user-avatar-content">
                  <div class="hexagon-image-30-32" data-src="img/avatar/07.jpg"></div>
                </div>

                <div class="user-avatar-progress">
                  <div class="hexagon-progress-40-44"></div>
                </div>

                <div class="user-avatar-progress-border">
                  <div class="hexagon-border-40-44"></div>
                </div>

                <div class="user-avatar-badge">
                  <div class="user-avatar-badge-border">
                    <div class="hexagon-22-24"></div>
                  </div>

                  <div class="user-avatar-badge-content">
                    <div class="hexagon-dark-16-18"></div>
                  </div>

                  <p class="user-avatar-badge-text">26</p>
                </div>
              </a>
              <p class="forum-post-user-title"><a href="profile-timeline.html">Omar Guevara</a></p>
              <p class="forum-post-user-tag organizer">Docente</p>
            </div>

            <div class="forum-post-info">
              <div class="forum-post-paragraph" [innerHTML]="course.attributes['description'].value"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- INFO DEL CURSO -->

    <!-- LECCIONES -->
    <div class="grid grid-4-4-4 centered">

      <div class="post-preview"  *ngFor="let lesson of lessons; let i = index">
        <figure class="post-preview-image liquid">
          <img src="assets/img/cover/10.jpg" alt="cover-19">
        </figure>

        <div class="post-preview-info fixed-height">
          <div class="post-preview-info-top">
            <p class="post-preview-timestamp">Lección {{i+1}}</p>
            <p class="post-preview-title routing"  (click)="routerLession()">{{lesson.attributes['title']}}}</p>
          </div>

          <div class="post-preview-info-bottom">
            <p class="post-preview-text"  [innerHTML]="lesson.attributes['description'].processed"></p>

            <a class="post-preview-link routing" (click)="routerLession()">Leer más...</a>
          </div>
        </div>
      </div>


    </div>
    <!-- LECCIONES -->

  </section>

 </div>


 <!--
        <figure class="post-preview-image liquid">
          <img src="assets/img/cover/10.jpg" alt="cover-19">
        </figure>

        <div class="post-preview-info fixed-height">
          <div class="post-preview-info-top">
            <p class="post-preview-timestamp">Lección 2</p>
            <p class="post-preview-title">¿Que dicción debes tener a la hora de hacer un stream?</p>
          </div>

          <div class="post-preview-info-bottom">
            <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

            <a class="post-preview-link" >Leer más...</a>
          </div>
        </div>
      </div>

      <div class="post-preview">
        <figure class="post-preview-image liquid">
          <img src="assets/img/cover/20.jpg" alt="cover-19">
        </figure>

        <div class="post-preview-info fixed-height">
          <div class="post-preview-info-top">
            <p class="post-preview-timestamp">Lección 3</p>
            <p class="post-preview-title">¿Cuanto debe durar un Stream?</p>
          </div>

          <div class="post-preview-info-bottom">
            <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

            <a class="post-preview-link" >Leer más...</a>
          </div>
        </div>
      </div>

      <div class="post-preview">
        <figure class="post-preview-image liquid">
          <img src="assets/img/cover/06.jpg" alt="cover-19">
        </figure>

        <div class="post-preview-info fixed-height">
          <div class="post-preview-info-top">
            <p class="post-preview-timestamp">Lección 4</p>
            <p class="post-preview-title">¿Mi público debe importarme?</p>
          </div>

          <div class="post-preview-info-bottom">
            <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

            <a class="post-preview-link" >Leer más...</a>
          </div>
        </div>
      </div>

      <div class="post-preview">
        <figure class="post-preview-image liquid">
          <img src="assets/img/cover/07.jpg" alt="cover-07">
        </figure>

        <div class="post-preview-info fixed-height">
          <div class="post-preview-info-top">
            <p class="post-preview-timestamp">Lección 5</p>
            <p class="post-preview-title">¿Qué problemas puedo tener?</p>
          </div>

          <div class="post-preview-info-bottom">
            <p class="post-preview-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut...</p>

            <a class="post-preview-link" >Leer más...</a>
          </div>
        </div>
      </div> -->
