import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

/* SERVICIOS */
import { ProfileService } from '../../services/profile.service';
import { CourseService } from '../../services/courses.service';
import { LogueoService } from '../../services/login.service';

/* MODELOS */
import { Profile } from '../../models/profile';
import { CoursesGrid } from '../../models/courses-grid';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  public profile: Profile; //detalle perfil
  public courses;
  public filesImgCover;
  public filesImgAvatar;
  public countCourse = {'count' : 0};

  show: boolean  = true;
  show2: boolean = false;
  show3: boolean = false;



  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _profileService: ProfileService,
    private _courseService: CourseService,
    private _login: LogueoService
  ){}

  ngOnInit(): void {
    this.getCourse();
  }

  getCourse(){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);
    this._courseService.getCourses(auth).subscribe(
      response => {
        this.courses = response.data;
        console.log(this.courses);
        this.decryptFiles(this.courses);
        console.log('Carga de cursos satisfactoriamente');
        this.countCourse['count'] += this.courses.length;
      },
      error => {
        console.log('Error Carga de Cursos');
        console.log(error);
      }
    )
  }

  /* DESENCRIPTAR ARCHIVOS */

  decryptFiles(courses){
    let key = localStorage.getItem("Sha");
    let auth = this._login.codeDecript(key);

    for (let urls in courses ){
      let urlCover = courses[urls].relationships['field_curso_portada'].links['related'].href;
      let urlImage = courses[urls].relationships['field_curso_imagen'].links['related'].href;

      /* Desencriptar la img de portada del curso */

      this._courseService.getFiles(urlCover,auth).subscribe(
        response => {
          this.filesImgCover = response.data;
          console.log(this.filesImgCover.attributes['uri'].url)
          this.courses[urls].relationships['field_curso_portada'].links['related'].href = this.filesImgCover.attributes['uri'].url;
          console.log(this.courses[urls].relationships['field_curso_portada'].links['related'].href)
        },
        error => {
          console.log('Error Carga de archivos');
          console.log(error);
        }
      )

      /* Desencriptar la img de perfil de curso */

      this._courseService.getFiles(urlImage,auth).subscribe(
        response => {
            this.filesImgAvatar = response.data;
            console.log(this.filesImgAvatar.attributes['uri'].url)
            this.courses[urls].relationships['field_curso_imagen'].links['related'].href = this.filesImgAvatar.attributes['uri'].url;
        },
        error => {
            console.log('Error Carga de archivos');
            console.log(error);
        }
      )
    }

    /* for(let url in courses){
      console.log(courses[url].relationships['field_curso_imagen'].links['related'].href )
    } */
  }

  /* VISTAS DE LOS CURSOS */

  switchGrid() {
    this.show  = true;this.show2 = false;this.show3 = false;
    $('#gridsmall').removeClass('active');
    $('#list').removeClass('active');
    $('#grid').addClass('active');
  }

  switchGridSmall() {
    this.show  = false;this.show2 = true;this.show3 = false;
    $('#grid').removeClass('active');
    $('#list').removeClass('active');
    $('#gridsmall').addClass('active');

  }

  switchList() {
    this.show  = false;this.show2 = false;this.show3 = true;
    $('#grid').removeClass('active');
    $('#gridsmall').removeClass('active');
    $('#list').addClass('active');
  }
}
