<head>
  <title>+Turismo | Cursos Inscritos</title>
</head>
<!-- MENU -->
<app-navbar></app-navbar>
<!-- MENU -->

<div class="profile-courses">
  <div class="content-grid">

    <!-- HEADER PROFILE -->
   <app-header-profile></app-header-profile>
    <!-- HEADER PROFILE -->

    <section class="section">
      <div class="section-header">
        <div class="section-header-info">
          <p class="section-pretitle">Navegación</p>

          <h2 class="section-title">Cursos Inscritos <span class="highlighted">{{this.countCourse['count']}}</span></h2>
        </div>
      </div>

      <div class="section-filters-bar v1">
        <div class="section-filters-bar-actions ">
          <div class="view-actions">
            <a class="view-action text-tooltip-tft-medium active" data-title="Cuadrícula Grande" (click)="switchGrid()" id="grid" >
              <svg class="view-action-icon icon-big-grid-view">
                <use xlink:href="#svg-big-grid-view"></use>
              </svg>
            </a>

            <a class="view-action text-tooltip-tft-medium"
             data-title="Cuadrícula Pequeña" (click)="switchGridSmall()" id="gridsmall">
              <svg class="view-action-icon icon-small-grid-view">
                <use xlink:href="#svg-small-grid-view"></use>
              </svg>
            </a>

            <a class="view-action text-tooltip-tft-medium" data-title="Lista" (click)="switchList()" id="list">
              <svg class="view-action-icon icon-list-grid-view">
                <use xlink:href="#svg-list-grid-view"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>


      <div class="loader loader--style6" title="loading" *ngIf="loading" align="center">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="150px" height="150px" viewBox="0 -3 50 50" style="enable-background:new 0 0 50 50; margin:2em;" xml:space="preserve">
          <rect x="0" y="13" width="15" height="70" fill="#3364FF">
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0s" dur="0.6s" repeatCount="indefinite" />
          </rect>
          <rect x="15" y="13" width="15" height="70" fill="#3364FF">
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0.15s" dur="0.6s" repeatCount="indefinite" />
          </rect>
          <rect x="30" y="13" width="15" height="70" fill="#3364FF">
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0.3s" dur="0.6s" repeatCount="indefinite" />
          </rect>
        </svg>
      </div>





      <div *ngIf="noshow">
        <h2 class="section-title" align="center" style="margin-top: 25px;"> Actualmente no tiene cursos inscritos</h2>
        <br>
        <div class="grid grid-4-4-4 centered">
          <p class="button secondary full"  (click)="routerCourses()">
            <svg class="button-icon icon-join-group">
              <use xlink:href="#svg-join-group"></use>
            </svg>
            ¡ Ir a cursos !
          </p>
        </div>
      </div>

      <div *ngIf="show">
        <app-courses-grid [data]='course'></app-courses-grid>
      </div>

      <div *ngIf="show2">
        <app-courses-small-grid  [data]='course'></app-courses-small-grid>
      </div>

      <div *ngIf="show3">
        <app-courses-list  [data]='course' ></app-courses-list>
      </div>
    </section>
  </div>
</div>
