<div class="account-hub-content">

  <div class="section-header">
    <div class="section-header-info">
      <p class="section-pretitle">Mis Cursos</p>

      <h2 class="section-title">Configuración de Cursos</h2>
    </div>
  </div>

  <div class="grid grid-3-3-3 centered-on-mobile">
    <div class="create-entity-box">
      <div class="create-entity-box-cover"></div>

      <div class="create-entity-box-avatar">
        <svg class="create-entity-box-avatar-icon icon-group">
          <use xlink:href="#svg-group"></use>
        </svg>
      </div>

      <div class="create-entity-box-info">
        <p class="create-entity-box-title">Crear Curso</p>

        <p class="create-entity-box-text">¡Comparte tu pasion con los demas!</p>

        <p class="button secondary full popup-manage-group-trigger">Empezar</p>
      </div>
    </div>

    <div class="user-preview small fixed-height-medium">
       <figure class="user-preview-cover liquid">
        <img src="assets/img/cover/29.jpg" alt="cover-29">
      </figure>
      <div class="user-preview-info">
        <div class="user-short-description small">
          <a class="user-short-description-avatar user-avatar no-stats" href="group-timeline.html">
            <div class="user-avatar-border">
              <div class="hexagon-100-108"></div>
            </div>

            <div class="user-avatar-content">
              <div class="hexagon-image-84-92" data-src="img/avatar/24.jpg"></div>
            </div>
          </a>
          <p class="user-short-description-title small"><a href="group-timeline.html">Diseñadores de Stream</a></p>
          <p class="user-short-description-text regular">¡Se reunen los Diseñadores!</p>
        </div>
        <p class="button white full popup-manage-group-trigger">Editar Curso</p>
      </div>
    </div>
  </div>
</div>
