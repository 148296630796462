import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-courses-grid',
  templateUrl: './courses-grid.component.html',
  styleUrls: ['./courses-grid.component.scss']
})
export class CoursesGridComponent implements OnInit {

  loadScripts() {
    const dynamicScripts = [
      "../assets/js/utils/liquidify.js",
      "../assets/js/global/global.hexagons.js"

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  @Input('data') cursos: any;

  constructor(
    private _router: Router
  ) {
    this.loadScripts();
  }

  ngOnInit(): void {
    let _this = this;
    console.log(this.cursos);
    setTimeout(function(){ _this.loadScripts();
    }, 3000);
  }

  routerDetailCourse(id){
    console.log(id);
    this._router.navigate(['detail-course/'+id]);
  }
}
