import { Component, OnInit } from '@angular/core';

/* Para utilizar jquery dentrodel OnInit*/
declare let $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  /* loadScripts() {
    const dynamicScripts = [
        "../assets/js/utils/app.js",
        "../assets/js/vendor/simplebar.min.js",
        "../assets/js/utils/liquidify.js",
        "../assets/js/vendor/xm_plugins.min.js",
        "../assets/js/global/global.hexagons.js",
        "../assets/js/global/global.tooltips.js",
        "../assets/js/global/global.accordions.js",
        "../assets/js/header/header.js",
        "../assets/js/sidebar/sidebar.js",
        "../assets/js/content/content.js",
        "../assets/js/form/form.utils.js",
        "../assets/js/utils/svg-loader.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  } */
  constructor() { }

  showProfile: boolean = true;
  showCourses: boolean = false;

  ngOnInit(): void {
    //this.loadScripts();
    $('#profile-birthday').click('blur', function(){
      $('#profile-birthday').type='text';
    });
    $('#profile-birthday').click('focus', function(){
      $('#profile-birthday').type='date';
    });
  }

  showMyProfile(){
    this.showProfile  = true;
    this.showCourses = false;
    $('#courses').removeClass('active');
    $('#profile').addClass('active');
    console.log('perfil');
  }
  showMyCourses(){
    this.showProfile  = false;
    this.showCourses = true;
    $('#courses').addClass('active');
    $('#profile').removeClass('active');
    console.log('cursos');
  }

}
