import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

/* Ruta Global */
import { GlobalUrl } from './globalUrl';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public url: string;

  constructor(
    public _http: HttpClient
  ){
    this.url = GlobalUrl.url;
  }

  test(){
    return 'Servicio de profile activo';
  }

  getProfile():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'profile', {headers: headers});
  }










}
