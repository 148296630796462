<div class="grid grid-4-4-4 centered">

  <div class="user-preview" *ngFor="let course of cursos">
    <figure class="user-preview-cover liquid">
     <img src="https://web-api.factoryim.co{{course.relationships['field_curso_portada'].links['related'].href}}">
    </figure>

    <div class="user-preview-info">
      <div class="tag-sticker">
        <svg class="tag-sticker-icon icon-public">
          <use xlink:href="#svg-public"></use>
        </svg>
      </div>

      <div class="user-short-description">
        <a class="user-short-description-avatar user-avatar medium no-stats">
          <div class="user-avatar-border">
            <div class="hexagon-120-130"></div>
          </div>

          <div class="user-avatar-content">
           <div class="hexagon-image-100-110" attr.data-src="https://web-api.factoryim.co{{course.relationships['field_curso_imagen'].links['related'].href}}"></div>
          </div>
        </a>

        <p class="user-short-description-title"><a>{{course.attributes['title']}}</a></p>

        <p class="user-short-description-text">{{course.attributes['area']}}</p>
      </div>

      <div class="user-stats">
        <div class="user-stat">
          <p class="user-stat-title">2</p>
          <p class="user-stat-text">Docentes</p>
        </div>

        <div class="user-stat">
          <p class="user-stat-title">52</p>
          <p class="user-stat-text">Lecciones</p>
        </div>

        <div class="user-stat">
          <p class="user-stat-title">143</p>
          <p class="user-stat-text">Estudiantes</p>
        </div>
      </div>

      <div class="user-preview-actions">
        <p class="button secondary full"  (click)="routerDetailCourse(course.id)">
          <svg class="button-icon icon-join-group">
            <use xlink:href="#svg-join-group"></use>
          </svg>
          ¡Seguir con el Curso!
        </p>
      </div>
    </div>
  </div>

</div>
