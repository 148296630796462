<div class="account-hub-content">
  <div class="section-header">
    <div class="section-header-info">
      <p class="section-pretitle">Mi Perfil</p>
      <h2 class="section-title">Información de Perfil</h2>
    </div>
  </div>

  <div class="grid-column">
    <div class="grid grid-3-3-3 centered">
      <div class="user-preview small fixed-height">
        <figure class="user-preview-cover liquid">
          <img src="assets/img/cover/01.jpg" alt="cover-01">
        </figure>

        <div class="user-preview-info">
          <div class="user-short-description small">
            <div class="user-short-description-avatar user-avatar">
              <div class="user-avatar-border">
                <div class="hexagon-100-110"></div>
              </div>
              <div class="user-avatar-content">
                <div class="hexagon-image-68-74" data-src="assets/img/avatar/01.jpg"></div>
              </div>
              <div class="user-avatar-progress">
                <div class="hexagon-progress-84-92"></div>
              </div>
              <div class="user-avatar-progress-border">
                <div class="hexagon-border-84-92"></div>
              </div>
              <div class="user-avatar-badge">
                <div class="user-avatar-badge-border">
                  <div class="hexagon-28-32"></div>
                </div>
                <div class="user-avatar-badge-content">
                  <div class="hexagon-dark-22-24"></div>
                </div>
                <p class="user-avatar-badge-text">23</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="upload-box">
        <svg class="upload-box-icon icon-members">
          <use xlink:href="#svg-members"></use>
        </svg>
        <p class="upload-box-title">Cambiar Avatar</p>
        <p class="upload-box-text">Tamaño minimo 110x110px</p>
      </div>

      <div class="upload-box">
        <svg class="upload-box-icon icon-photos">
          <use xlink:href="#svg-photos"></use>
        </svg>
        <p class="upload-box-title">Cambiar Fondo de Perfil</p>
        <p class="upload-box-text">Tamaño minimo 1184x300px</p>
      </div>
    </div>

    <div class="widget-box">
      <p class="widget-box-title">Acerca de su Perfil</p>
      <div class="widget-box-content">
        <form class="form">
          <div class="form-row split">
            <div class="form-item">
              <div class="form-input small active">
                <label for="profile-name">Nombre de Perfil</label>
                <input type="text" id="profile-name" name="profile_name" value="Mariana Domingues">
              </div>
            </div>

            <div class="form-item">
              <div class="form-input small active">
                <label for="profile-tagline">Lema</label>
                <input type="text" id="profile-tagline" name="profile_tagline" value="El pesimismo lleva a la debilidad. El optimismo, al poder">
              </div>
            </div>
          </div>

          <div class="form-row split">
            <div class="form-item">
              <div class="form-input small full">
                <textarea id="profile-description" name="profile_description" placeholder="Escribe una pequeña descripción sobre ti ..."></textarea>
              </div>
            </div>

            <div class="form-item">
              <div class="form-input small active">
                <label for="profile-public-email">Correo Electronico</label>
                <input type="text" id="profile-public-email" name="profile_public_email" value="mariana@gmail.com">
              </div>
            </div>
          </div>

          <div class="form-row split">
             <div class="form-item">
              <div class="form-select">
                <label for="profile-country">País</label>
                <select id="profile-country" name="profile_country">
                  <option value="0">Selecciona tu pais</option>
                  <option value="1" selected>Colombia</option>
                </select>
                <svg class="form-select-icon icon-small-arrow">
                  <use xlink:href="#svg-small-arrow"></use>
                </svg>
              </div>
             </div>
            <div class="form-item">
              <div class="form-select">
                <label for="profile-city">Ciudad</label>
                <select id="profile-city" name="profile_city">
                  <option value="0">Selecciona tu Ciudad</option>
                  <option value="1" selected>Villavicencio</option>
                  <option value="2" selected>Bógota</option>
                  <option value="3" selected>Medellin</option>
                  <option value="4" selected>Barranquilla</option>
                  <option value="5" selected>Bucaramanga</option>
                </select>
                <svg class="form-select-icon icon-small-arrow">
                  <use xlink:href="#svg-small-arrow"></use>
                </svg>
              </div>
            </div>
          </div>

          <div class="form-row split">
            <div class="form-item">
              <div class="form-input-decorated">
                <div class="form-input small active">
                  <label for="profile-birthday">Cumpleaños</label>
                  <input type="date" id="profile-birthday" name="profile_birthday" value="Agosto 24, 1997">
                </div>
                <svg class="form-input-icon icon-events">
                  <use xlink:href="#svg-events"></use>
                </svg>
              </div>
            </div>

            <div class="form-item">
              <div class="form-input small active">
                <label for="profile-birthplace">Lugar de Nacimiento</label>
                <input type="text" id="profile-birthplace" name="profile_birthplace" value="Bogota, Colombia">
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
